import React from "react"
import { Link } from "gatsby"
import OrderOnline from "../OrderOnline"

const HeroNav = () => {
  return (
    <div className="hero-nav">
      <ul className="nav-list">
        {/* <li>
					<Link
						className="nav-item"
						activeClassName="active"
						to="/"
					>
						Home
            </Link>
				</li> */}
        <li>
          <Link className="nav-item" activeClassName="active" to="/menu">
            Menu
          </Link>
        </li>
        <li>
          <Link className="nav-item" activeClassName="active" to="/gallery">
            Gallery
          </Link>
        </li>
        {/* <li>
					<Link
						className="nav-item"
						activeClassName="active"
						to="/careers"
					>
						Careers
            </Link>
				</li>
				<li>
					<Link
						className="nav-item"
						activeClassName="active"
						to="/contact"
					>
						Contact
            </Link>
				</li> */}
        <li>
          <OrderOnline />
        </li>
      </ul>
    </div>
  )
}

export default HeroNav
