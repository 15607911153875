import React from 'react';
import Food from '../images/food.png'
import Patterns from '../images/patterns.png'

const AboutUs = () => {
	return (
		<div className="about-us" id="next">
			<img className="food" src={Food} alt="food" />
			<img className="patterns" src={Patterns} alt="patterns" />
			<div className="about-us-content">
				<h2>About us</h2>
				<p>Taking you back to the alleyways of Vietnam where you will find specific stalls specializing in one thing- here at sit lo, we specialize in pho. Our menu is short and sweet to highlight the labor of love that has gone into each of your bowls.</p>
				<p>Our beef bone broth is simmered for 72  hours to bring out the umami of the beef bones. Each bone is parboiled, scrubbed & rinsed to achieve the clarity in our soup just like our mother's would make at home.</p>
				<p>All cuts of meat are carefully cut to specific size to achieve both flavor and texture to compliment our fresh noodles. Fresh noodles are also another highlight in each bowl just like you would find it in Vietnam. The thin pho noodles that many of us are used to in the states are available to sub but don't write off the balance of fresh soft rice noodles until you try it because we promise you serving it like you would find in our motherland is an experience in itself.</p>
			</div>
		</div>
	);
};

export default AboutUs;