import React from 'react';
import HeroSlider, { Slide, OverlayContainer } from "hero-slider";
import Restaurant from '../../images/hero/Restaurant2.jpg';
import RestaurantThree from '../../images/hero/Restaurant3.jpg';
import Boba from '../../images/hero/boba.jpg';
import Logo from '../../images/brandLogos/masterLogo.png';
import HeroNav from './HeroNav';
import SocialMedia from '../SocialMedia'

const images = [Boba, Restaurant, RestaurantThree];

// const Screen = () => {
// 	if (typeof window === 'undefined')
// 		return <></>

// 	return (
// 		<MenuNav />
// 	)
// }

const Hero = () => {
	return (
		<HeroSlider
			slidingAnimation="top_to_bottom"
			orientation="vertical"
			initialSlide={1}
			style={{
				background: "#222"
			}}
			settings={{
				slidingDuration: 400,
				slidingDelay: 100,
				shouldAutoplay: true,
				shouldDisplayButtons: false,
				autoplayDuration: 8000,
				height: "100vh"
			}}
		>
			<OverlayContainer>
				<SocialMedia />
				<div className="hero">
					<img className="hero-logo" src={Logo} alt="Sit Lo Saigon Restaurant Logo" />
					<HeroNav />
				</div>
			</OverlayContainer>
			{
				images.map((image, index) => (
					<div key={index}>
						<Slide
							// navDescription={image}
							background={{
								backgroundImage: image,

							}}
						>
						</Slide>
					</div>
				))
			}
		</HeroSlider>
	);
};

export default Hero;