import React from "react"
import { Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Layout from "../components/layout"
import Hero from "../components/Hero/Hero"
import ParallaxCard from "../components/ParallaxCard"
import AboutUs from "../components/AboutUs"
import Card from "../components/Card"
import Header from "../components/header"

import Restaurant from "../images/hero/restaurant.jpg"
import RestaurantTwo from "../images/hero/Restaurant3.jpg"
import Popup from "../components/Popup/Popup"

const HomePage = () => {
  return (
    <>
      <GatsbySeo
        title="SitLo Saigon "
        description="best pho, best pho elk grove, best pho davis, Best pho in Elk Grove, best pho in Sacramento county, Vietnamese, pho, noodles, broth and meat."
        canonical="/"
      />
      <Layout>
        <Header homeNav />
        <h1 className="hidden-text">best pho in elk grove, best pho elk grove, best pho</h1>
        {/* <Popup/> */}
        <Hero />
        <AboutUs />
        <ParallaxCard
          image={RestaurantTwo}
          height="100vh"
          children={
            <div className="parallax-content">
              <Link to="/menu">
                <h1>
                  Explore our <span>menu</span>
                </h1>
              </Link>
            </div>
          }
        />
        <ParallaxCard
          image={Restaurant}
          height="100vh"
          children={
            <div className="parallax-content">
              <Link to="/gallery">
                <h1>
                  View our <span>gallery</span>
                </h1>
              </Link>
            </div>
          }
        />
      </Layout>
    </>
  )
}

export default HomePage
